@keyframes flow-ltr {
  0% {
    left: -5%;
    opacity: 0; }
  50% {
    left: 50%;
    opacity: 0.3; }
  100% {
    left: 100%;
    opacity: 0; } }

@keyframes flow-rtl {
  0% {
    right: -5%;
    opacity: 0; }
  50% {
    right: 50%;
    opacity: 0.3; }
  100% {
    right: 100%;
    opacity: 0; } }

@keyframes flow-ttb {
  0% {
    top: -5%;
    opacity: 0; }
  50% {
    top: 50%;
    opacity: 0.3; }
  100% {
    top: 100%;
    opacity: 0; } }

@keyframes flow-btt {
  0% {
    bottom: -5%;
    opacity: 0; }
  50% {
    bottom: 50%;
    opacity: 0.3; }
  100% {
    bottom: 100%;
    opacity: 0; } }

@keyframes flow-corner-tl {
  0% {
    left: 0%;
    bottom: 0%;
    opacity: 0; }
  50% {
    left: 50%;
    bottom: 50%;
    opacity: 0.3; }
  100% {
    left: 100%;
    bottom: 100%;
    opacity: 0; } }

@keyframes flow-corner-tr {
  0% {
    right: 0%;
    bottom: 0%;
    opacity: 0; }
  50% {
    right: 50%;
    bottom: 50%;
    opacity: 0.3; }
  100% {
    right: 100%;
    bottom: 100%;
    opacity: 0; } }

@keyframes flow-corner-bl {
  0% {
    left: 0%;
    bottom: 0%;
    opacity: 0; }
  50% {
    left: 50%;
    bottom: 50%;
    opacity: 0.3; }
  100% {
    left: 100%;
    bottom: 100%;
    opacity: 0; } }

@keyframes flow-corner-br {
  0% {
    right: 0%;
    bottom: 0%;
    opacity: 0; }
  50% {
    right: 50%;
    bottom: 50%;
    opacity: 0.3; }
  100% {
    right: 100%;
    bottom: 100%;
    opacity: 0; } }

[class^=qribbon__] .glow,
[class^=qribbon__] .glow {
  background: white;
  width: 40px;
  height: 100%;
  top: 0;
  position: absolute;
  animation-name: flow-ltr;
  animation-duration: var(--qribbon-glow-speed, 1.5s);
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-direction: normal;
  animation-iteration-count: var(--qribbon-glow-iteration-count, 1.5s);
  animation-fill-mode: forwards;
  animation-play-state: running;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

[class^=qribbon__horizontal--right] .glow {
  animation-name: flow-rtl;
  background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

[class^=qribbon__vertical--top] .glow {
  width: 100%;
  height: 40px;
  right: 0;
  animation-name: flow-ttb;
  transform: skew(0);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

[class^=qribbon__vertical--bottom] .glow {
  width: 100%;
  height: 40px;
  right: 0;
  bottom: 0;
  top: unset;
  animation-name: flow-btt;
  transform: skew(0);
  background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

[class^=qribbon__corner--top-left] div .glow {
  height: 100%;
  width: 40px;
  animation-name: flow-corner-tl;
  transform: skew(45deg);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

[class^=qribbon__corner--top-right] div .glow {
  height: 100%;
  width: 40px;
  left: auto;
  animation-name: flow-corner-tr;
  transform: skew(-45deg);
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

[class^=qribbon__corner--bottom-left] div .glow {
  height: 100%;
  width: 40px;
  animation-name: flow-corner-bl;
  transform: skew(-45deg);
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

[class^=qribbon__corner--bottom-right] div .glow {
  height: 100%;
  width: 40px;
  animation-name: flow-corner-br;
  left: auto;
  transform: skew(45deg);
  background: linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, white 100%); }

.qribbon__container {
  display: flex; }
  .qribbon__container.inline {
    display: inline-flex; }

.qribbon__container.right {
  justify-content: flex-end; }

.q-toolbar .qribbon__container {
  margin-left: -12px;
  flex-grow: 1;
  margin-right: -12px; }

.qribbon__corner--top-left {
  top: -10px;
  left: -10px; }
  .qribbon__corner--top-left::before, .qribbon__corner--top-left::after {
    border-top-color: transparent;
    border-left-color: transparent; }
  .qribbon__corner--top-left::before {
    top: 0;
    right: 8px;
    z-index: 9999; }
  .qribbon__corner--top-left::after {
    bottom: 8px;
    left: 0;
    z-index: 9999; }
  .qribbon__corner--top-left div {
    right: -35px;
    top: 40px;
    transform: rotate(-45deg); }

.qribbon__corner--bottom-left {
  bottom: -10px;
  left: -10px; }
  .qribbon__corner--bottom-left::before, .qribbon__corner--bottom-left::after {
    border-bottom-color: transparent;
    border-left-color: transparent; }
  .qribbon__corner--bottom-left::before {
    bottom: 0;
    right: 8px;
    z-index: 9999; }
  .qribbon__corner--bottom-left::after {
    top: 8px;
    left: 0;
    z-index: 9999; }
  .qribbon__corner--bottom-left div {
    right: -35px;
    bottom: 40px;
    transform: rotate(45deg); }

.qribbon__corner--top-right {
  top: -10px;
  right: -10px; }
  .qribbon__corner--top-right::before, .qribbon__corner--top-right::after {
    border-top-color: transparent;
    border-right-color: transparent; }
  .qribbon__corner--top-right::before {
    top: 0;
    left: 8px;
    z-index: 9999; }
  .qribbon__corner--top-right::after {
    bottom: 8px;
    right: 0;
    z-index: 9999; }
  .qribbon__corner--top-right div {
    left: -35px;
    top: 40px;
    transform: rotate(45deg); }

.qribbon__corner--bottom-right {
  bottom: -10px;
  right: -10px; }
  .qribbon__corner--bottom-right::before, .qribbon__corner--bottom-right::after {
    border-bottom-color: transparent;
    border-right-color: transparent; }
  .qribbon__corner--bottom-right::before {
    bottom: 0;
    left: 8px;
    z-index: 9999; }
  .qribbon__corner--bottom-right::after {
    top: 8px;
    right: 0;
    z-index: 9999; }
  .qribbon__corner--bottom-right div {
    left: -35px;
    bottom: 40px;
    transform: rotate(-45deg); }

.qribbon__corner--top-left,
.qribbon__corner--top-right,
.qribbon__corner--bottom-left,
.qribbon__corner--bottom-right {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: absolute;
  background: transparent !important;
  z-index: 1; }
  .qribbon__corner--top-left::before, .qribbon__corner--top-left::after,
  .qribbon__corner--top-right::before,
  .qribbon__corner--top-right::after,
  .qribbon__corner--bottom-left::before,
  .qribbon__corner--bottom-left::after,
  .qribbon__corner--bottom-right::before,
  .qribbon__corner--bottom-right::after {
    position: absolute;
    z-index: -1;
    content: '';
    display: block;
    border: 5px solid var(--qribbon-leaf-color, black); }
  .qribbon__corner--top-left div,
  .qribbon__corner--top-right div,
  .qribbon__corner--bottom-left div,
  .qribbon__corner--bottom-right div {
    position: absolute;
    display: block;
    width: 250px;
    padding: 7px 0;
    background-color: var(--qribbon-background-color, black);
    text-transform: uppercase;
    text-align: center;
    color: var(--qribbon-text-color, black); }
    .qribbon__corner--top-left div a,
    .qribbon__corner--top-right div a,
    .qribbon__corner--bottom-left div a,
    .qribbon__corner--bottom-right div a {
      color: var(--qribbon-text-color, black); }

.qribbon__container {
  display: flex; }
  .qribbon__container.inline {
    display: inline-flex; }

.qribbon__container.right {
  justify-content: flex-end; }

.q-toolbar .qribbon__container {
  margin-left: -12px;
  flex-grow: 1;
  margin-right: -12px; }

.qribbon__horizontal--left.leaf-bottom::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-left: 10px solid transparent;
  left: 0; }

.qribbon__horizontal--left.leaf-top::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-right: 10px solid var(--qribbon-leaf-color, black);
  top: -10px;
  left: 0; }

.qribbon__horizontal--right.leaf-bottom::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-right: 10px solid transparent;
  right: 0; }

.qribbon__horizontal--right.leaf-top::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-left: 10px solid var(--qribbon-leaf-color, black);
  top: -10px;
  right: 0; }

.qribbon__horizontal--left-full.leaf-bottom::before,
.qribbon__horizontal--right-full.leaf-bottom::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-left: 10px solid transparent;
  left: 0; }

.qribbon__horizontal--left-full.leaf-bottom::after,
.qribbon__horizontal--right-full.leaf-bottom::after {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-right: 10px solid transparent;
  right: 0; }

.qribbon__horizontal--left-full.leaf-top::before,
.qribbon__horizontal--right-full.leaf-top::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-right: 10px solid var(--qribbon-leaf-color, black);
  top: -10px;
  left: 0; }

.qribbon__horizontal--left-full.leaf-top::after,
.qribbon__horizontal--right-full.leaf-top::after {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-left: 10px solid var(--qribbon-leaf-color, black);
  top: -10px;
  right: 0; }

.qribbon__horizontal--left, .qribbon__horizontal--left-full,
.qribbon__horizontal--right-full,
.qribbon__horizontal--right,
.qribbon__horizontal--left-full,
.qribbon__horizontal--right-full {
  padding: 5px 12px;
  background: var(--qribbon-background-color, black);
  color: var(--qribbon-text-color, white);
  z-index: 1;
  position: relative; }

.qribbon__horizontal--left {
  left: -10px;
  right: inherit; }

.qribbon__horizontal--right {
  margin-right: -10px; }

.qribbon__horizontal--left-full,
.qribbon__horizontal--right-full {
  left: -10px;
  flex-grow: 1;
  margin-right: -20px; }

[class*=horizontal--left].decorate-point-in::after,
[class*=horizontal--right].decorate-point-in::after {
  content: "";
  display: block;
  position: absolute;
  right: -12px;
  top: 0;
  bottom: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 15px solid var(--qribbon-background-color, black);
  border-bottom: 15px solid var(--qribbon-background-color, black); }

[class*=horizontal--right].decorate-point-in::after {
  right: inherit;
  left: -12px;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 15px solid var(--qribbon-background-color, black);
  border-bottom: 15px solid var(--qribbon-background-color, black); }

[class*=horizontal--left].decorate-point-out::after,
[class*=horizontal--right].decorate-point-out::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  top: 0;
  right: -24px;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 12px solid transparent;
  border-left: 12px solid var(--qribbon-background-color, black); }

[class*=horizontal--right].decorate-point-out::after {
  left: -24px;
  right: inherit;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 12px solid transparent;
  border-right: 12px solid var(--qribbon-background-color, black); }

[class*=horizontal--left].decorate-rounded-in {
  padding-right: 24px;
  background: radial-gradient(circle at right, transparent 15px, var(--qribbon-background-color, black) 16px); }

[class*=horizontal--right].decorate-rounded-in {
  padding-left: 24px;
  background: radial-gradient(circle at left, transparent 15px, var(--qribbon-background-color, black) 16px); }

[class*=horizontal--left].decorate-rounded-out {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px; }

[class*=horizontal--right].decorate-rounded-out {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px; }

.qribbon__container {
  display: flex; }
  .qribbon__container.inline {
    display: inline-flex; }

.qribbon__container.right {
  justify-content: flex-end; }

.q-toolbar .qribbon__container {
  margin-left: -12px;
  flex-grow: 1;
  margin-right: -12px; }

.qribbon__vertical--top-left.leaf-left::before, .leaf-left.qribbon__vertical--left-full::before,
.qribbon__vertical--top-right.leaf-left::before,
.leaf-left.qribbon__vertical--right-full::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-right: 10px solid var(--qribbon-leaf-color, black);
  left: -10px;
  top: 0; }

.qribbon__vertical--top-left.leaf-right::before, .leaf-right.qribbon__vertical--left-full::before,
.qribbon__vertical--top-right.leaf-right::before,
.leaf-right.qribbon__vertical--right-full::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: 10px solid transparent;
  border-left: 10px solid var(--qribbon-leaf-color, black);
  right: -10px;
  top: 0; }

.qribbon__vertical--bottom-left.leaf-left::before, .leaf-left.qribbon__vertical--left-full::before,
.qribbon__vertical--bottom-right.leaf-left::before,
.leaf-left.qribbon__vertical--right-full::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: none;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--qribbon-leaf-color, black);
  left: -10px;
  bottom: 0; }

.qribbon__vertical--bottom-left.leaf-right::before, .leaf-right.qribbon__vertical--left-full::before,
.qribbon__vertical--bottom-right.leaf-right::before,
.leaf-right.qribbon__vertical--right-full::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: none;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--qribbon-leaf-color, black);
  right: -10px;
  bottom: 0; }

.qribbon__vertical--right-full.leaf-left::before,
.qribbon__vertical--left-full.leaf-left::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: none;
  border-bottom: 10px solid transparent;
  border-right: 10px solid var(--qribbon-leaf-color, black);
  left: -10px;
  top: unset;
  bottom: 0; }

.qribbon__vertical--right-full.leaf-left::after,
.qribbon__vertical--left-full.leaf-left::after {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-bottom: none;
  border-top: 10px solid transparent;
  border-right: 10px solid var(--qribbon-leaf-color, black);
  left: -10px;
  bottom: unset;
  top: 0; }

.qribbon__vertical--right-full.leaf-right::before,
.qribbon__vertical--left-full.leaf-right::before {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-top: none;
  border-bottom: 10px solid transparent;
  border-left: 10px solid var(--qribbon-leaf-color, black);
  right: -10px;
  bottom: 0; }

.qribbon__vertical--right-full.leaf-right::after,
.qribbon__vertical--left-full.leaf-right::after {
  width: 10px;
  height: 0px;
  bottom: -10px;
  border-top: 10px solid var(--qribbon-leaf-color, black);
  display: block;
  position: absolute;
  content: "";
  border-bottom: none;
  border-top: 10px solid transparent;
  border-left: 10px solid var(--qribbon-leaf-color, black);
  right: -10px;
  top: 0; }

.qribbon__vertical--top-left, .qribbon__vertical--left-full,
.qribbon__vertical--bottom-left,
.qribbon__vertical--left-full {
  left: 0;
  right: inherit; }

.qribbon__vertical--bottom-right, .qribbon__vertical--right-full,
.qribbon__vertical--top-right,
.qribbon__vertical--right-full {
  right: 20px;
  left: inherit; }


.qribbon__vertical--bottom-left,
.qribbon__vertical--left-full, .qribbon__vertical--bottom-right, .qribbon__vertical--right-full {
  position: absolute;
  padding: 12px 5px;
  bottom: -10px;
  writing-mode: vertical-rl;
  background: var(--qribbon-background-color, black);
  color: var(--qribbon-text-color, white);
  z-index: 100; }

.qribbon__vertical--top-left, .qribbon__vertical--left-full,
.qribbon__vertical--top-right,
.qribbon__vertical--right-full {
  position: absolute;
  padding: 12px 5px;
  top: -10px;
  writing-mode: vertical-rl;
  background: var(--qribbon-background-color, black);
  color: var(--qribbon-text-color, white);
  z-index: 100; }

.qribbon__vertical--left-full {
  left: 0;
  right: inherit; }

.qribbon__vertical--right-full {
  right: 20px;
  left: inherit; }

[class*=vertical--top].decorate-point-in::after,
[class*=vertical--bottom].decorate-point-in::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -12px;
  left: 0;
  right: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 15px solid var(--qribbon-background-color, black);
  border-left: 15px solid var(--qribbon-background-color, black); }

[class*=vertical--bottom].decorate-point-in::after {
  bottom: inherit;
  top: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-right: 15px solid var(--qribbon-background-color, black);
  border-left: 15px solid var(--qribbon-background-color, black); }

[class*=vertical--top].decorate-point-out::after,
[class*=vertical--bottom].decorate-point-out::after {
  content: "";
  display: block;
  position: absolute;
  top: -24px;
  left: 0;
  right: 0;
  border-top: 12px solid transparent;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-bottom: 12px solid var(--qribbon-background-color, black); }

[class*=vertical--top].decorate-point-out::after {
  bottom: -24px;
  top: inherit;
  border-bottom: 12px solid transparent;
  border-right: 15px solid transparent;
  border-left: 15px solid transparent;
  border-top: 12px solid var(--qribbon-background-color, black); }

[class*=vertical--top].decorate-rounded-in {
  padding-bottom: 24px;
  background: radial-gradient(circle at bottom, transparent 15px, var(--qribbon-background-color, black) 16px); }

[class*=vertical--bottom].decorate-rounded-in {
  padding-top: 24px;
  background: radial-gradient(circle at top, transparent 15px, var(--qribbon-background-color, black) 16px); }

[class*=vertical--bottom].decorate-rounded-out {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px; }

[class*=vertical--top].decorate-rounded-out {
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px; }

:root {
  --q-color-light: #bdbdbd;
  --q-color-faded: #777;
  --q-color-black: #000;
  --q-color-white: #fff;
  --q-color-red: #f44336;
  --q-color-red-1: #ffebee;
  --q-color-red-2: #ffcdd2;
  --q-color-red-3: #ef9a9a;
  --q-color-red-4: #e57373;
  --q-color-red-5: #ef5350;
  --q-color-red-6: #f44336;
  --q-color-red-7: #e53935;
  --q-color-red-8: #d32f2f;
  --q-color-red-9: #c62828;
  --q-color-red-10: #b71c1c;
  --q-color-red-11: #ff8a80;
  --q-color-red-12: #ff5252;
  --q-color-red-13: #ff1744;
  --q-color-red-14: #d50000;
  --q-color-pink: #e91e63;
  --q-color-pink-1: #fce4ec;
  --q-color-pink-2: #f8bbd0;
  --q-color-pink-3: #f48fb1;
  --q-color-pink-4: #f06292;
  --q-color-pink-5: #ec407a;
  --q-color-pink-6: #e91e63;
  --q-color-pink-7: #d81b60;
  --q-color-pink-8: #c2185b;
  --q-color-pink-9: #ad1457;
  --q-color-pink-10: #880e4f;
  --q-color-pink-11: #ff80ab;
  --q-color-pink-12: #ff4081;
  --q-color-pink-13: #f50057;
  --q-color-pink-14: #c51162;
  --q-color-purple: #9c27b0;
  --q-color-purple-1: #f3e5f5;
  --q-color-purple-2: #e1bee7;
  --q-color-purple-3: #ce93d8;
  --q-color-purple-4: #ba68c8;
  --q-color-purple-5: #ab47bc;
  --q-color-purple-6: #9c27b0;
  --q-color-purple-7: #8e24aa;
  --q-color-purple-8: #7b1fa2;
  --q-color-purple-9: #6a1b9a;
  --q-color-purple-10: #4a148c;
  --q-color-purple-11: #ea80fc;
  --q-color-purple-12: #e040fb;
  --q-color-purple-13: #d500f9;
  --q-color-purple-14: #aa00ff;
  --q-color-deep-purple: #673ab7;
  --q-color-deep-purple-1: #ede7f6;
  --q-color-deep-purple-2: #d1c4e9;
  --q-color-deep-purple-3: #b39ddb;
  --q-color-deep-purple-4: #9575cd;
  --q-color-deep-purple-5: #7e57c2;
  --q-color-deep-purple-6: #673ab7;
  --q-color-deep-purple-7: #5e35b1;
  --q-color-deep-purple-8: #512da8;
  --q-color-deep-purple-9: #4527a0;
  --q-color-deep-purple-10: #311b92;
  --q-color-deep-purple-11: #b388ff;
  --q-color-deep-purple-12: #7c4dff;
  --q-color-deep-purple-13: #651fff;
  --q-color-deep-purple-14: #6200ea;
  --q-color-indigo: #3f51b5;
  --q-color-indigo-1: #e8eaf6;
  --q-color-indigo-2: #c5cae9;
  --q-color-indigo-3: #9fa8da;
  --q-color-indigo-4: #7986cb;
  --q-color-indigo-5: #5c6bc0;
  --q-color-indigo-6: #3f51b5;
  --q-color-indigo-7: #3949ab;
  --q-color-indigo-8: #303f9f;
  --q-color-indigo-9: #283593;
  --q-color-indigo-10: #1a237e;
  --q-color-indigo-11: #8c9eff;
  --q-color-indigo-12: #536dfe;
  --q-color-indigo-13: #3d5afe;
  --q-color-indigo-14: #304ffe;
  --q-color-blue: #2196f3;
  --q-color-blue-1: #e3f2fd;
  --q-color-blue-2: #bbdefb;
  --q-color-blue-3: #90caf9;
  --q-color-blue-4: #64b5f6;
  --q-color-blue-5: #42a5f5;
  --q-color-blue-6: #2196f3;
  --q-color-blue-7: #1e88e5;
  --q-color-blue-8: #1976d2;
  --q-color-blue-9: #1565c0;
  --q-color-blue-10: #0d47a1;
  --q-color-blue-11: #82b1ff;
  --q-color-blue-12: #448aff;
  --q-color-blue-13: #2979ff;
  --q-color-blue-14: #2962ff;
  --q-color-light-blue: #03a9f4;
  --q-color-light-blue-1: #e1f5fe;
  --q-color-light-blue-2: #b3e5fc;
  --q-color-light-blue-3: #81d4fa;
  --q-color-light-blue-4: #4fc3f7;
  --q-color-light-blue-5: #29b6f6;
  --q-color-light-blue-6: #03a9f4;
  --q-color-light-blue-7: #039be5;
  --q-color-light-blue-8: #0288d1;
  --q-color-light-blue-9: #0277bd;
  --q-color-light-blue-10: #01579b;
  --q-color-light-blue-11: #80d8ff;
  --q-color-light-blue-12: #40c4ff;
  --q-color-light-blue-13: #00b0ff;
  --q-color-light-blue-14: #0091ea;
  --q-color-cyan: #00bcd4;
  --q-color-cyan-1: #e0f7fa;
  --q-color-cyan-2: #b2ebf2;
  --q-color-cyan-3: #80deea;
  --q-color-cyan-4: #4dd0e1;
  --q-color-cyan-5: #26c6da;
  --q-color-cyan-6: #00bcd4;
  --q-color-cyan-7: #00acc1;
  --q-color-cyan-8: #0097a7;
  --q-color-cyan-9: #00838f;
  --q-color-cyan-10: #006064;
  --q-color-cyan-11: #84ffff;
  --q-color-cyan-12: #18ffff;
  --q-color-cyan-13: #00e5ff;
  --q-color-cyan-14: #00b8d4;
  --q-color-teal: #009688;
  --q-color-teal-1: #e0f2f1;
  --q-color-teal-2: #b2dfdb;
  --q-color-teal-3: #80cbc4;
  --q-color-teal-4: #4db6ac;
  --q-color-teal-5: #26a69a;
  --q-color-teal-6: #009688;
  --q-color-teal-7: #00897b;
  --q-color-teal-8: #00796b;
  --q-color-teal-9: #00695c;
  --q-color-teal-10: #004d40;
  --q-color-teal-11: #a7ffeb;
  --q-color-teal-12: #64ffda;
  --q-color-teal-13: #1de9b6;
  --q-color-teal-14: #00bfa5;
  --q-color-green: #4caf50;
  --q-color-green-1: #e8f5e9;
  --q-color-green-2: #c8e6c9;
  --q-color-green-3: #a5d6a7;
  --q-color-green-4: #81c784;
  --q-color-green-5: #66bb6a;
  --q-color-green-6: #4caf50;
  --q-color-green-7: #43a047;
  --q-color-green-8: #388e3c;
  --q-color-green-9: #2e7d32;
  --q-color-green-10: #1b5e20;
  --q-color-green-11: #b9f6ca;
  --q-color-green-12: #69f0ae;
  --q-color-green-13: #00e676;
  --q-color-green-14: #00c853;
  --q-color-light-green: #8bc34a;
  --q-color-light-green-1: #f1f8e9;
  --q-color-light-green-2: #dcedc8;
  --q-color-light-green-3: #c5e1a5;
  --q-color-light-green-4: #aed581;
  --q-color-light-green-5: #9ccc65;
  --q-color-light-green-6: #8bc34a;
  --q-color-light-green-7: #7cb342;
  --q-color-light-green-8: #689f38;
  --q-color-light-green-9: #558b2f;
  --q-color-light-green-10: #33691e;
  --q-color-light-green-11: #ccff90;
  --q-color-light-green-12: #b2ff59;
  --q-color-light-green-13: #76ff03;
  --q-color-light-green-14: #64dd17;
  --q-color-lime: #cddc39;
  --q-color-lime-1: #f9fbe7;
  --q-color-lime-2: #f0f4c3;
  --q-color-lime-3: #e6ee9c;
  --q-color-lime-4: #dce775;
  --q-color-lime-5: #d4e157;
  --q-color-lime-6: #cddc39;
  --q-color-lime-7: #c0ca33;
  --q-color-lime-8: #afb42b;
  --q-color-lime-9: #9e9d24;
  --q-color-lime-10: #827717;
  --q-color-lime-11: #f4ff81;
  --q-color-lime-12: #eeff41;
  --q-color-lime-13: #c6ff00;
  --q-color-lime-14: #aeea00;
  --q-color-yellow: #ffeb3b;
  --q-color-yellow-1: #fffde7;
  --q-color-yellow-2: #fff9c4;
  --q-color-yellow-3: #fff59d;
  --q-color-yellow-4: #fff176;
  --q-color-yellow-5: #ffee58;
  --q-color-yellow-6: #ffeb3b;
  --q-color-yellow-7: #fdd835;
  --q-color-yellow-8: #fbc02d;
  --q-color-yellow-9: #f9a825;
  --q-color-yellow-10: #f57f17;
  --q-color-yellow-11: #ffff8d;
  --q-color-yellow-12: #ffff00;
  --q-color-yellow-13: #ffea00;
  --q-color-yellow-14: #ffd600;
  --q-color-amber: #ffc107;
  --q-color-amber-1: #fff8e1;
  --q-color-amber-2: #ffecb3;
  --q-color-amber-3: #ffe082;
  --q-color-amber-4: #ffd54f;
  --q-color-amber-5: #ffca28;
  --q-color-amber-6: #ffc107;
  --q-color-amber-7: #ffb300;
  --q-color-amber-8: #ffa000;
  --q-color-amber-9: #ff8f00;
  --q-color-amber-10: #ff6f00;
  --q-color-amber-11: #ffe57f;
  --q-color-amber-12: #ffd740;
  --q-color-amber-13: #ffc400;
  --q-color-amber-14: #ffab00;
  --q-color-orange: #ff9800;
  --q-color-orange-1: #fff3e0;
  --q-color-orange-2: #ffe0b2;
  --q-color-orange-3: #ffcc80;
  --q-color-orange-4: #ffb74d;
  --q-color-orange-5: #ffa726;
  --q-color-orange-6: #ff9800;
  --q-color-orange-7: #fb8c00;
  --q-color-orange-8: #f57c00;
  --q-color-orange-9: #ef6c00;
  --q-color-orange-10: #e65100;
  --q-color-orange-11: #ffd180;
  --q-color-orange-12: #ffab40;
  --q-color-orange-13: #ff9100;
  --q-color-orange-14: #ff6d00;
  --q-color-deep-orange: #ff5722;
  --q-color-deep-orange-1: #fbe9e7;
  --q-color-deep-orange-2: #ffccbc;
  --q-color-deep-orange-3: #ffab91;
  --q-color-deep-orange-4: #ff8a65;
  --q-color-deep-orange-5: #ff7043;
  --q-color-deep-orange-6: #ff5722;
  --q-color-deep-orange-7: #f4511e;
  --q-color-deep-orange-8: #e64a19;
  --q-color-deep-orange-9: #d84315;
  --q-color-deep-orange-10: #bf360c;
  --q-color-deep-orange-11: #ff9e80;
  --q-color-deep-orange-12: #ff6e40;
  --q-color-deep-orange-13: #ff3d00;
  --q-color-deep-orange-14: #dd2c00;
  --q-color-brown: #795548;
  --q-color-brown-1: #efebe9;
  --q-color-brown-2: #d7ccc8;
  --q-color-brown-3: #bcaaa4;
  --q-color-brown-4: #a1887f;
  --q-color-brown-5: #8d6e63;
  --q-color-brown-6: #795548;
  --q-color-brown-7: #6d4c41;
  --q-color-brown-8: #5d4037;
  --q-color-brown-9: #4e342e;
  --q-color-brown-10: #3e2723;
  --q-color-brown-11: #d7ccc8;
  --q-color-brown-12: #bcaaa4;
  --q-color-brown-13: #8d6e63;
  --q-color-brown-14: #5d4037;
  --q-color-grey: #9e9e9e;
  --q-color-grey-1: #fafafa;
  --q-color-grey-2: #f5f5f5;
  --q-color-grey-3: #eeeeee;
  --q-color-grey-4: #e0e0e0;
  --q-color-grey-5: #bdbdbd;
  --q-color-grey-6: #9e9e9e;
  --q-color-grey-7: #757575;
  --q-color-grey-8: #616161;
  --q-color-grey-9: #424242;
  --q-color-grey-10: #212121;
  --q-color-grey-11: #f5f5f5;
  --q-color-grey-12: #eeeeee;
  --q-color-grey-13: #bdbdbd;
  --q-color-grey-14: #616161;
  --q-color-blue-grey: #607d8b;
  --q-color-blue-grey-1: #eceff1;
  --q-color-blue-grey-2: #cfd8dc;
  --q-color-blue-grey-3: #b0bec5;
  --q-color-blue-grey-4: #90a4ae;
  --q-color-blue-grey-5: #78909c;
  --q-color-blue-grey-6: #607d8b;
  --q-color-blue-grey-7: #546e7a;
  --q-color-blue-grey-8: #455a64;
  --q-color-blue-grey-9: #37474f;
  --q-color-blue-grey-10: #263238;
  --q-color-blue-grey-11: #cfd8dc;
  --q-color-blue-grey-12: #b0bec5;
  --q-color-blue-grey-13: #78909c;
  --q-color-blue-grey-14: #455a64; }

.border-color-primary {
  border-color: var(--q-color-primary); }

.border-color-secondary {
  border-color: var(--q-color-secondary); }

.border-color-accent {
  border-color: var(--q-color-accent); }

.border-color-positive {
  border-color: var(--q-color-positive); }

.border-color-negative {
  border-color: var(--q-color-negative); }

.border-color-info {
  border-color: var(--q-color-info); }

.border-color-warning {
  border-color: var(--q-color-warning); }

.border-color-light {
  border-color: var(--q-color-light); }

.border-color-dark {
  border-color: var(--q-color-dark); }

.border-color-faded {
  border-color: var(--q-color-faded); }

.border-color-black {
  border-color: var(--q-color-black); }

.border-color-white {
  border-color: var(--q-color-white); }

.border-color-red {
  border-color: var(--q-color-red); }

.border-color-red-1 {
  border-color: var(--q-color-red-1); }

.border-color-red-2 {
  border-color: var(--q-color-red-2); }

.border-color-red-3 {
  border-color: var(--q-color-red-3); }

.border-color-red-4 {
  border-color: var(--q-color-red-4); }

.border-color-red-5 {
  border-color: var(--q-color-red-5); }

.border-color-red-6 {
  border-color: var(--q-color-red-6); }

.border-color-red-7 {
  border-color: var(--q-color-red-7); }

.border-color-red-8 {
  border-color: var(--q-color-red-8); }

.border-color-red-9 {
  border-color: var(--q-color-red-9); }

.border-color-red-10 {
  border-color: var(--q-color-red-10); }

.border-color-red-11 {
  border-color: var(--q-color-red-11); }

.border-color-red-12 {
  border-color: var(--q-color-red-12); }

.border-color-red-13 {
  border-color: var(--q-color-red-13); }

.border-color-red-14 {
  border-color: var(--q-color-red-14); }

.border-color-pink {
  border-color: var(--q-color-pink); }

.border-color-pink-1 {
  border-color: var(--q-color-pink-1); }

.border-color-pink-2 {
  border-color: var(--q-color-pink-2); }

.border-color-pink-3 {
  border-color: var(--q-color-pink-3); }

.border-color-pink-4 {
  border-color: var(--q-color-pink-4); }

.border-color-pink-5 {
  border-color: var(--q-color-pink-5); }

.border-color-pink-6 {
  border-color: var(--q-color-pink-6); }

.border-color-pink-7 {
  border-color: var(--q-color-pink-7); }

.border-color-pink-8 {
  border-color: var(--q-color-pink-8); }

.border-color-pink-9 {
  border-color: var(--q-color-pink-9); }

.border-color-pink-10 {
  border-color: var(--q-color-pink-10); }

.border-color-pink-11 {
  border-color: var(--q-color-pink-11); }

.border-color-pink-12 {
  border-color: var(--q-color-pink-12); }

.border-color-pink-13 {
  border-color: var(--q-color-pink-13); }

.border-color-pink-14 {
  border-color: var(--q-color-pink-14); }

.border-color-purple {
  border-color: var(--q-color-purple); }

.border-color-purple-1 {
  border-color: var(--q-color-purple-1); }

.border-color-purple-2 {
  border-color: var(--q-color-purple-2); }

.border-color-purple-3 {
  border-color: var(--q-color-purple-3); }

.border-color-purple-4 {
  border-color: var(--q-color-purple-4); }

.border-color-purple-5 {
  border-color: var(--q-color-purple-5); }

.border-color-purple-6 {
  border-color: var(--q-color-purple-6); }

.border-color-purple-7 {
  border-color: var(--q-color-purple-7); }

.border-color-purple-8 {
  border-color: var(--q-color-purple-8); }

.border-color-purple-9 {
  border-color: var(--q-color-purple-9); }

.border-color-purple-10 {
  border-color: var(--q-color-purple-10); }

.border-color-purple-11 {
  border-color: var(--q-color-purple-11); }

.border-color-purple-12 {
  border-color: var(--q-color-purple-12); }

.border-color-purple-13 {
  border-color: var(--q-color-purple-13); }

.border-color-purple-14 {
  border-color: var(--q-color-purple-14); }

.border-color-deep-purple {
  border-color: var(--q-color-deep-purple); }

.border-color-deep-purple-1 {
  border-color: var(--q-color-deep-purple-1); }

.border-color-deep-purple-2 {
  border-color: var(--q-color-deep-purple-2); }

.border-color-deep-purple-3 {
  border-color: var(--q-color-deep-purple-3); }

.border-color-deep-purple-4 {
  border-color: var(--q-color-deep-purple-4); }

.border-color-deep-purple-5 {
  border-color: var(--q-color-deep-purple-5); }

.border-color-deep-purple-6 {
  border-color: var(--q-color-deep-purple-6); }

.border-color-deep-purple-7 {
  border-color: var(--q-color-deep-purple-7); }

.border-color-deep-purple-8 {
  border-color: var(--q-color-deep-purple-8); }

.border-color-deep-purple-9 {
  border-color: var(--q-color-deep-purple-9); }

.border-color-deep-purple-10 {
  border-color: var(--q-color-deep-purple-10); }

.border-color-deep-purple-11 {
  border-color: var(--q-color-deep-purple-11); }

.border-color-deep-purple-12 {
  border-color: var(--q-color-deep-purple-12); }

.border-color-deep-purple-13 {
  border-color: var(--q-color-deep-purple-13); }

.border-color-deep-purple-14 {
  border-color: var(--q-color-deep-purple-14); }

.border-color-indigo {
  border-color: var(--q-color-indigo); }

.border-color-indigo-1 {
  border-color: var(--q-color-indigo-1); }

.border-color-indigo-2 {
  border-color: var(--q-color-indigo-2); }

.border-color-indigo-3 {
  border-color: var(--q-color-indigo-3); }

.border-color-indigo-4 {
  border-color: var(--q-color-indigo-4); }

.border-color-indigo-5 {
  border-color: var(--q-color-indigo-5); }

.border-color-indigo-6 {
  border-color: var(--q-color-indigo-6); }

.border-color-indigo-7 {
  border-color: var(--q-color-indigo-7); }

.border-color-indigo-8 {
  border-color: var(--q-color-indigo-8); }

.border-color-indigo-9 {
  border-color: var(--q-color-indigo-9); }

.border-color-indigo-10 {
  border-color: var(--q-color-indigo-10); }

.border-color-indigo-11 {
  border-color: var(--q-color-indigo-11); }

.border-color-indigo-12 {
  border-color: var(--q-color-indigo-12); }

.border-color-indigo-13 {
  border-color: var(--q-color-indigo-13); }

.border-color-indigo-14 {
  border-color: var(--q-color-indigo-14); }

.border-color-blue {
  border-color: var(--q-color-blue); }

.border-color-blue-1 {
  border-color: var(--q-color-blue-1); }

.border-color-blue-2 {
  border-color: var(--q-color-blue-2); }

.border-color-blue-3 {
  border-color: var(--q-color-blue-3); }

.border-color-blue-4 {
  border-color: var(--q-color-blue-4); }

.border-color-blue-5 {
  border-color: var(--q-color-blue-5); }

.border-color-blue-6 {
  border-color: var(--q-color-blue-6); }

.border-color-blue-7 {
  border-color: var(--q-color-blue-7); }

.border-color-blue-8 {
  border-color: var(--q-color-blue-8); }

.border-color-blue-9 {
  border-color: var(--q-color-blue-9); }

.border-color-blue-10 {
  border-color: var(--q-color-blue-10); }

.border-color-blue-11 {
  border-color: var(--q-color-blue-11); }

.border-color-blue-12 {
  border-color: var(--q-color-blue-12); }

.border-color-blue-13 {
  border-color: var(--q-color-blue-13); }

.border-color-blue-14 {
  border-color: var(--q-color-blue-14); }

.border-color-light-blue {
  border-color: var(--q-color-light-blue); }

.border-color-light-blue-1 {
  border-color: var(--q-color-light-blue-1); }

.border-color-light-blue-2 {
  border-color: var(--q-color-light-blue-2); }

.border-color-light-blue-3 {
  border-color: var(--q-color-light-blue-3); }

.border-color-light-blue-4 {
  border-color: var(--q-color-light-blue-4); }

.border-color-light-blue-5 {
  border-color: var(--q-color-light-blue-5); }

.border-color-light-blue-6 {
  border-color: var(--q-color-light-blue-6); }

.border-color-light-blue-7 {
  border-color: var(--q-color-light-blue-7); }

.border-color-light-blue-8 {
  border-color: var(--q-color-light-blue-8); }

.border-color-light-blue-9 {
  border-color: var(--q-color-light-blue-9); }

.border-color-light-blue-10 {
  border-color: var(--q-color-light-blue-10); }

.border-color-light-blue-11 {
  border-color: var(--q-color-light-blue-11); }

.border-color-light-blue-12 {
  border-color: var(--q-color-light-blue-12); }

.border-color-light-blue-13 {
  border-color: var(--q-color-light-blue-13); }

.border-color-light-blue-14 {
  border-color: var(--q-color-light-blue-14); }

.border-color-cyan {
  border-color: var(--q-color-cyan); }

.border-color-cyan-1 {
  border-color: var(--q-color-cyan-1); }

.border-color-cyan-2 {
  border-color: var(--q-color-cyan-2); }

.border-color-cyan-3 {
  border-color: var(--q-color-cyan-3); }

.border-color-cyan-4 {
  border-color: var(--q-color-cyan-4); }

.border-color-cyan-5 {
  border-color: var(--q-color-cyan-5); }

.border-color-cyan-6 {
  border-color: var(--q-color-cyan-6); }

.border-color-cyan-7 {
  border-color: var(--q-color-cyan-7); }

.border-color-cyan-8 {
  border-color: var(--q-color-cyan-8); }

.border-color-cyan-9 {
  border-color: var(--q-color-cyan-9); }

.border-color-cyan-10 {
  border-color: var(--q-color-cyan-10); }

.border-color-cyan-11 {
  border-color: var(--q-color-cyan-11); }

.border-color-cyan-12 {
  border-color: var(--q-color-cyan-12); }

.border-color-cyan-13 {
  border-color: var(--q-color-cyan-13); }

.border-color-cyan-14 {
  border-color: var(--q-color-cyan-14); }

.border-color-teal {
  border-color: var(--q-color-teal); }

.border-color-teal-1 {
  border-color: var(--q-color-teal-1); }

.border-color-teal-2 {
  border-color: var(--q-color-teal-2); }

.border-color-teal-3 {
  border-color: var(--q-color-teal-3); }

.border-color-teal-4 {
  border-color: var(--q-color-teal-4); }

.border-color-teal-5 {
  border-color: var(--q-color-teal-5); }

.border-color-teal-6 {
  border-color: var(--q-color-teal-6); }

.border-color-teal-7 {
  border-color: var(--q-color-teal-7); }

.border-color-teal-8 {
  border-color: var(--q-color-teal-8); }

.border-color-teal-9 {
  border-color: var(--q-color-teal-9); }

.border-color-teal-10 {
  border-color: var(--q-color-teal-10); }

.border-color-teal-11 {
  border-color: var(--q-color-teal-11); }

.border-color-teal-12 {
  border-color: var(--q-color-teal-12); }

.border-color-teal-13 {
  border-color: var(--q-color-teal-13); }

.border-color-teal-14 {
  border-color: var(--q-color-teal-14); }

.border-color-green {
  border-color: var(--q-color-green); }

.border-color-green-1 {
  border-color: var(--q-color-green-1); }

.border-color-green-2 {
  border-color: var(--q-color-green-2); }

.border-color-green-3 {
  border-color: var(--q-color-green-3); }

.border-color-green-4 {
  border-color: var(--q-color-green-4); }

.border-color-green-5 {
  border-color: var(--q-color-green-5); }

.border-color-green-6 {
  border-color: var(--q-color-green-6); }

.border-color-green-7 {
  border-color: var(--q-color-green-7); }

.border-color-green-8 {
  border-color: var(--q-color-green-8); }

.border-color-green-9 {
  border-color: var(--q-color-green-9); }

.border-color-green-10 {
  border-color: var(--q-color-green-10); }

.border-color-green-11 {
  border-color: var(--q-color-green-11); }

.border-color-green-12 {
  border-color: var(--q-color-green-12); }

.border-color-green-13 {
  border-color: var(--q-color-green-13); }

.border-color-green-14 {
  border-color: var(--q-color-green-14); }

.border-color-light-green {
  border-color: var(--q-color-light-green); }

.border-color-light-green-1 {
  border-color: var(--q-color-light-green-1); }

.border-color-light-green-2 {
  border-color: var(--q-color-light-green-2); }

.border-color-light-green-3 {
  border-color: var(--q-color-light-green-3); }

.border-color-light-green-4 {
  border-color: var(--q-color-light-green-4); }

.border-color-light-green-5 {
  border-color: var(--q-color-light-green-5); }

.border-color-light-green-6 {
  border-color: var(--q-color-light-green-6); }

.border-color-light-green-7 {
  border-color: var(--q-color-light-green-7); }

.border-color-light-green-8 {
  border-color: var(--q-color-light-green-8); }

.border-color-light-green-9 {
  border-color: var(--q-color-light-green-9); }

.border-color-light-green-10 {
  border-color: var(--q-color-light-green-10); }

.border-color-light-green-11 {
  border-color: var(--q-color-light-green-11); }

.border-color-light-green-12 {
  border-color: var(--q-color-light-green-12); }

.border-color-light-green-13 {
  border-color: var(--q-color-light-green-13); }

.border-color-light-green-14 {
  border-color: var(--q-color-light-green-14); }

.border-color-lime {
  border-color: var(--q-color-lime); }

.border-color-lime-1 {
  border-color: var(--q-color-lime-1); }

.border-color-lime-2 {
  border-color: var(--q-color-lime-2); }

.border-color-lime-3 {
  border-color: var(--q-color-lime-3); }

.border-color-lime-4 {
  border-color: var(--q-color-lime-4); }

.border-color-lime-5 {
  border-color: var(--q-color-lime-5); }

.border-color-lime-6 {
  border-color: var(--q-color-lime-6); }

.border-color-lime-7 {
  border-color: var(--q-color-lime-7); }

.border-color-lime-8 {
  border-color: var(--q-color-lime-8); }

.border-color-lime-9 {
  border-color: var(--q-color-lime-9); }

.border-color-lime-10 {
  border-color: var(--q-color-lime-10); }

.border-color-lime-11 {
  border-color: var(--q-color-lime-11); }

.border-color-lime-12 {
  border-color: var(--q-color-lime-12); }

.border-color-lime-13 {
  border-color: var(--q-color-lime-13); }

.border-color-lime-14 {
  border-color: var(--q-color-lime-14); }

.border-color-yellow {
  border-color: var(--q-color-yellow); }

.border-color-yellow-1 {
  border-color: var(--q-color-yellow-1); }

.border-color-yellow-2 {
  border-color: var(--q-color-yellow-2); }

.border-color-yellow-3 {
  border-color: var(--q-color-yellow-3); }

.border-color-yellow-4 {
  border-color: var(--q-color-yellow-4); }

.border-color-yellow-5 {
  border-color: var(--q-color-yellow-5); }

.border-color-yellow-6 {
  border-color: var(--q-color-yellow-6); }

.border-color-yellow-7 {
  border-color: var(--q-color-yellow-7); }

.border-color-yellow-8 {
  border-color: var(--q-color-yellow-8); }

.border-color-yellow-9 {
  border-color: var(--q-color-yellow-9); }

.border-color-yellow-10 {
  border-color: var(--q-color-yellow-10); }

.border-color-yellow-11 {
  border-color: var(--q-color-yellow-11); }

.border-color-yellow-12 {
  border-color: var(--q-color-yellow-12); }

.border-color-yellow-13 {
  border-color: var(--q-color-yellow-13); }

.border-color-yellow-14 {
  border-color: var(--q-color-yellow-14); }

.border-color-amber {
  border-color: var(--q-color-amber); }

.border-color-amber-1 {
  border-color: var(--q-color-amber-1); }

.border-color-amber-2 {
  border-color: var(--q-color-amber-2); }

.border-color-amber-3 {
  border-color: var(--q-color-amber-3); }

.border-color-amber-4 {
  border-color: var(--q-color-amber-4); }

.border-color-amber-5 {
  border-color: var(--q-color-amber-5); }

.border-color-amber-6 {
  border-color: var(--q-color-amber-6); }

.border-color-amber-7 {
  border-color: var(--q-color-amber-7); }

.border-color-amber-8 {
  border-color: var(--q-color-amber-8); }

.border-color-amber-9 {
  border-color: var(--q-color-amber-9); }

.border-color-amber-10 {
  border-color: var(--q-color-amber-10); }

.border-color-amber-11 {
  border-color: var(--q-color-amber-11); }

.border-color-amber-12 {
  border-color: var(--q-color-amber-12); }

.border-color-amber-13 {
  border-color: var(--q-color-amber-13); }

.border-color-amber-14 {
  border-color: var(--q-color-amber-14); }

.border-color-orange {
  border-color: var(--q-color-orange); }

.border-color-orange-1 {
  border-color: var(--q-color-orange-1); }

.border-color-orange-2 {
  border-color: var(--q-color-orange-2); }

.border-color-orange-3 {
  border-color: var(--q-color-orange-3); }

.border-color-orange-4 {
  border-color: var(--q-color-orange-4); }

.border-color-orange-5 {
  border-color: var(--q-color-orange-5); }

.border-color-orange-6 {
  border-color: var(--q-color-orange-6); }

.border-color-orange-7 {
  border-color: var(--q-color-orange-7); }

.border-color-orange-8 {
  border-color: var(--q-color-orange-8); }

.border-color-orange-9 {
  border-color: var(--q-color-orange-9); }

.border-color-orange-10 {
  border-color: var(--q-color-orange-10); }

.border-color-orange-11 {
  border-color: var(--q-color-orange-11); }

.border-color-orange-12 {
  border-color: var(--q-color-orange-12); }

.border-color-orange-13 {
  border-color: var(--q-color-orange-13); }

.border-color-orange-14 {
  border-color: var(--q-color-orange-14); }

.border-color-deep-orange {
  border-color: var(--q-color-deep-orange); }

.border-color-deep-orange-1 {
  border-color: var(--q-color-deep-orange-1); }

.border-color-deep-orange-2 {
  border-color: var(--q-color-deep-orange-2); }

.border-color-deep-orange-3 {
  border-color: var(--q-color-deep-orange-3); }

.border-color-deep-orange-4 {
  border-color: var(--q-color-deep-orange-4); }

.border-color-deep-orange-5 {
  border-color: var(--q-color-deep-orange-5); }

.border-color-deep-orange-6 {
  border-color: var(--q-color-deep-orange-6); }

.border-color-deep-orange-7 {
  border-color: var(--q-color-deep-orange-7); }

.border-color-deep-orange-8 {
  border-color: var(--q-color-deep-orange-8); }

.border-color-deep-orange-9 {
  border-color: var(--q-color-deep-orange-9); }

.border-color-deep-orange-10 {
  border-color: var(--q-color-deep-orange-10); }

.border-color-deep-orange-11 {
  border-color: var(--q-color-deep-orange-11); }

.border-color-deep-orange-12 {
  border-color: var(--q-color-deep-orange-12); }

.border-color-deep-orange-13 {
  border-color: var(--q-color-deep-orange-13); }

.border-color-deep-orange-14 {
  border-color: var(--q-color-deep-orange-14); }

.border-color-brown {
  border-color: var(--q-color-brown); }

.border-color-brown-1 {
  border-color: var(--q-color-brown-1); }

.border-color-brown-2 {
  border-color: var(--q-color-brown-2); }

.border-color-brown-3 {
  border-color: var(--q-color-brown-3); }

.border-color-brown-4 {
  border-color: var(--q-color-brown-4); }

.border-color-brown-5 {
  border-color: var(--q-color-brown-5); }

.border-color-brown-6 {
  border-color: var(--q-color-brown-6); }

.border-color-brown-7 {
  border-color: var(--q-color-brown-7); }

.border-color-brown-8 {
  border-color: var(--q-color-brown-8); }

.border-color-brown-9 {
  border-color: var(--q-color-brown-9); }

.border-color-brown-10 {
  border-color: var(--q-color-brown-10); }

.border-color-brown-11 {
  border-color: var(--q-color-brown-11); }

.border-color-brown-12 {
  border-color: var(--q-color-brown-12); }

.border-color-brown-13 {
  border-color: var(--q-color-brown-13); }

.border-color-brown-14 {
  border-color: var(--q-color-brown-14); }

.border-color-grey {
  border-color: var(--q-color-grey); }

.border-color-grey-1 {
  border-color: var(--q-color-grey-1); }

.border-color-grey-2 {
  border-color: var(--q-color-grey-2); }

.border-color-grey-3 {
  border-color: var(--q-color-grey-3); }

.border-color-grey-4 {
  border-color: var(--q-color-grey-4); }

.border-color-grey-5 {
  border-color: var(--q-color-grey-5); }

.border-color-grey-6 {
  border-color: var(--q-color-grey-6); }

.border-color-grey-7 {
  border-color: var(--q-color-grey-7); }

.border-color-grey-8 {
  border-color: var(--q-color-grey-8); }

.border-color-grey-9 {
  border-color: var(--q-color-grey-9); }

.border-color-grey-10 {
  border-color: var(--q-color-grey-10); }

.border-color-grey-11 {
  border-color: var(--q-color-grey-11); }

.border-color-grey-12 {
  border-color: var(--q-color-grey-12); }

.border-color-grey-13 {
  border-color: var(--q-color-grey-13); }

.border-color-grey-14 {
  border-color: var(--q-color-grey-14); }

.border-color-blue-grey {
  border-color: var(--q-color-blue-grey); }

.border-color-blue-grey-1 {
  border-color: var(--q-color-blue-grey-1); }

.border-color-blue-grey-2 {
  border-color: var(--q-color-blue-grey-2); }

.border-color-blue-grey-3 {
  border-color: var(--q-color-blue-grey-3); }

.border-color-blue-grey-4 {
  border-color: var(--q-color-blue-grey-4); }

.border-color-blue-grey-5 {
  border-color: var(--q-color-blue-grey-5); }

.border-color-blue-grey-6 {
  border-color: var(--q-color-blue-grey-6); }

.border-color-blue-grey-7 {
  border-color: var(--q-color-blue-grey-7); }

.border-color-blue-grey-8 {
  border-color: var(--q-color-blue-grey-8); }

.border-color-blue-grey-9 {
  border-color: var(--q-color-blue-grey-9); }

.border-color-blue-grey-10 {
  border-color: var(--q-color-blue-grey-10); }

.border-color-blue-grey-11 {
  border-color: var(--q-color-blue-grey-11); }

.border-color-blue-grey-12 {
  border-color: var(--q-color-blue-grey-12); }

.border-color-blue-grey-13 {
  border-color: var(--q-color-blue-grey-13); }

.border-color-blue-grey-14 {
  border-color: var(--q-color-blue-grey-14); }
